import { API_ROOT_URL, headers } from '~constants/api-constants'

const updateUser = async (accessToken, tokenSecret, userId) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('user_jwt')}`,
    },
    body: JSON.stringify({
      accessToken,
      tokenSecret,
    }),
  }
  const response = await fetch(
    `${API_ROOT_URL}/users/${userId}`,
    requestOptions
  )
  const result = await response.json()
  return result
}

const getTweets = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('user_jwt')}`,
    },
  }
  const response = await fetch(`${API_ROOT_URL}/posts/all`, requestOptions)
  const result = await response.json()
  return result
}

const claimPost = async (postId) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('user_jwt')}`,
    },
    body: JSON.stringify({
      claimed: true,
    }),
  }
  const response = await fetch(
    `${API_ROOT_URL}/posts/${postId}`,
    requestOptions
  )
  const result = await response.json()
  return result
}

const getUserInfo = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('user_jwt')}`,
    },
  }
  const response = await fetch(`${API_ROOT_URL}/users/getinfo`, requestOptions)
  const result = await response.json()
  return result
}

export default {
  updateUser,
  getTweets,
  claimPost,
  getUserInfo,
}
