// eslint-disable-next-line import/prefer-default-export
export const getDateTitle = ({ from, to, isMobile }) => {
  const monthFormat = isMobile ? 'LLL' : 'LLLL'

  const fromFormat =
    from?.year === to?.year ? `dd ${monthFormat}` : `dd ${monthFormat} yyyy`

  const fromLabel = from?.toFormat(fromFormat)
  const toLabel = to?.toFormat(`dd ${monthFormat} yyyy`)

  return `${fromLabel} - ${toLabel}`
}
