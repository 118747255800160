export const DAYS_RANGE = 10

const DATA = [
  { replies: '3', date: '2021-12-04T08:00:00.000Z', link: '/', claimed: false },
  { replies: '5', date: '2021-12-01T08:00:00.000Z', link: '/', claimed: false },
  { replies: '6', date: '2021-11-30T08:00:00.000Z', link: '/', claimed: false },
  {
    replies: '29',
    date: '2021-11-29T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  { replies: '4', date: '2021-11-28T08:00:00.000Z', link: '/', claimed: true },
  { replies: '5', date: '2021-11-27T08:00:00.000Z', link: '/', claimed: true },
  {
    replies: '14',
    date: '2021-11-26T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  {
    replies: '24',
    date: '2021-11-25T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  {
    replies: '15',
    date: '2021-11-24T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  {
    replies: '21',
    date: '2021-11-23T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  {
    replies: '20',
    date: '2021-11-22T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  {
    replies: '20',
    date: '2021-11-21T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
  {
    replies: '20',
    date: '2021-11-20T08:00:00.000Z',
    link: '/',
    claimed: true,
  },
]

export default DATA
