import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import Icon from '~components/Icon'
import useCountdownTimer from '~hooks/useCountdownTimer'
import * as s from './style.module.scss'
import api from '~apis/api'

const InfoItem = ({ date, replies, link, claimed, postId }) => {
  const dt = DateTime.fromISO(date)
  const [isClaimed, setIsClaimed] = useState(claimed)

  useEffect(() => {
    setIsClaimed(claimed)
  }, [])

  const [countdownDiff] = useCountdownTimer(date)

  const dateTitle = dt.toFormat('dd LLL yyyy, ccc')
  const timeTitle = dt.toFormat('hh:mm a').toLowerCase()

  const handleClaim = async () => {
    await api.claimPost(postId)
    setIsClaimed(true)
  }

  return (
    <div className={s.info_item} key={date}>
      <span className={s.info_replies}>{replies}</span>
      <span className={s.info_replies_title}>Replies</span>
      {countdownDiff ? (
        <div className={s.info_countdown}>
          {countdownDiff.toFormat('hh:mm:ss')}
        </div>
      ) : (
        <Button
          onClick={handleClaim}
          className={s.info_btn}
          disabled={isClaimed}
        >
          {isClaimed ? 'Claimed' : 'Claim'}
        </Button>
      )}
      <span className={s.info_date}>
        {dateTitle} <span className={s.info_time}>{timeTitle}</span>
      </span>
      <Button
        as="a"
        variant="mini"
        className={s.info_link}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="arrow-outer" size={16} />
      </Button>
    </div>
  )
}

InfoItem.propTypes = {
  date: PropTypes.string.isRequired,
  replies: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  claimed: PropTypes.bool.isRequired,
  postId: PropTypes.string.isRequired,
}

export default InfoItem
