import React, { useState, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import Header from '../Header'
import InfoItem from '../InfoItem'
import { DAYS_RANGE } from '../../constants'
import * as s from './style.module.scss'

const DataContent = ({ data, minDate, maxDate }) => {
  const [{ from, to }, setRange] = useState(() => ({
    from: DateTime.now().minus({ days: DAYS_RANGE - 1 }),
    to: DateTime.now(),
  }))

  useEffect(() => {
    const latestDate = DateTime.fromISO(maxDate)
    setRange({
      from: latestDate.minus({ days: DAYS_RANGE - 1 }),
      to: latestDate,
    })
  }, [maxDate])

  const pageData = useMemo(
    () =>
      data?.filter(({ date }) => {
        const dt = DateTime.fromISO(date)
        return dt >= from && dt <= to
      }),
    [from, to]
  )

  return (
    <>
      <Header
        {...{
          from,
          to,
          minDate,
          maxDate,
          setRange,
        }}
      />
      <div className={s.main}>
        <div className={s.list}>
          {pageData?.map((infoItem) => (
            <InfoItem {...infoItem} key={infoItem.date} />
          ))}
        </div>
      </div>
    </>
  )
}

export default DataContent
