import React from 'react'
import cn from 'classnames'
import { Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import Icon from '~components/Icon'
import useWindowSize from '~hooks/useWindowSize'
import { getDateTitle } from './utils'
import { DAYS_RANGE } from '../../constants'
import * as s from './style.module.scss'

const Header = ({ isEmpty, from, to, minDate, maxDate, setRange }) => {
  const [width] = useWindowSize()
  const isMobile = width < 640

  const earliest = DateTime.fromISO(minDate)
  const letest = DateTime.fromISO(maxDate)

  const dateTitle = getDateTitle({ from, to, isMobile })

  const handlePrev = () => {
    const predictedFrom = from?.minus({ days: DAYS_RANGE })
    setRange({
      from: predictedFrom >= earliest ? predictedFrom : earliest,
      to: to?.minus({ days: DAYS_RANGE }),
    })
  }
  const handleNext = () => {
    setRange({
      from: to?.plus({ days: 1 }),
      to: to?.plus({ days: DAYS_RANGE }),
    })
  }

  return (
    <div
      className={cn(s.header, {
        [s.empty]: isEmpty,
      })}
    >
      <p className={s.range}>{isEmpty ? 'N/A' : dateTitle}</p>
      <div className={s.nav}>
        <Button
          onClick={handlePrev}
          variant="mini"
          className={s.nav_btn}
          disabled={isEmpty || from <= earliest}
        >
          <Icon name="chevron-left" size={16} />
        </Button>
        <Button
          onClick={handleNext}
          variant="mini"
          className={cn(s.nav_btn, s.next)}
          disabled={isEmpty || to >= letest}
        >
          <Icon name="chevron-left" size={16} />
        </Button>
      </div>
    </div>
  )
}

export default Header
