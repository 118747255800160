import React, { useState } from 'react'
import OverlayContext from '~contexts/OverlayContext'

const OverlayStateProvider = (props) => {
  const { children, ...rest } = props

  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false)
  const [toastStack, setToastStack] = useState([])

  const addToastToStack = (toast) => {
    setToastStack((prevState) => [...prevState, toast])
  }

  return (
    <OverlayContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isModalRegisterVisible,
        setIsModalRegisterVisible,
        toastStack,
        addToastToStack,
      }}
      {...rest}
    >
      {children}
    </OverlayContext.Provider>
  )
}

export default OverlayStateProvider
