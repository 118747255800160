import React, { useState, useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import DataContent from './components/DataContent'
import EmptyContent from './components/EmptyContent'
import * as s from './style.module.scss'
import api from '~apis/api'
import useTwitterAuth from '~hooks/useTwitterAuth'

const Info = () => {
  const [data, setData] = useState([])
  const { isTwitterAuthVerified: isLogin } = useTwitterAuth()
  const loginRef = useRef(isLogin)

  const isEmpty = !data?.length

  useEffect(() => {
    const fetchAllTweetInfo = async () => {
      const result = await api.getTweets()

      if (loginRef.current) {
        setData(result)
      }
      return result
    }

    loginRef.current = isLogin

    if (isLogin) {
      fetchAllTweetInfo()
    } else {
      setData([])
    }
  }, [isLogin])

  return (
    <Container className={s.info}>
      {isEmpty ? (
        <EmptyContent />
      ) : (
        <DataContent
          data={data}
          minDate={data?.[data.length - 1].date}
          maxDate={data?.[0]?.date}
        />
      )}
    </Container>
  )
}

export default Info
