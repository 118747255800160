import React from 'react'
import Icon from '~components/Icon'
import Header from '../Header'
import * as s from './style.module.scss'

const EmptyContent = () => {
  return (
    <>
      <Header isEmpty />
      <div className={s.wrap}>
        <div className={s.heading}>
          <div className={s.prev}>
            <div className={s.logo}>GM</div>
            <div className={s.title}>Good Morning</div>
            <div className={s.twitter}>@goodmorning</div>
          </div>
          <div className={s.icon}>
            <Icon name="twitter" size={[27, 22]} />
          </div>
        </div>
        <div className={s.content}>
          <span className={s.text}>Good Morning!</span>
        </div>
      </div>
    </>
  )
}

export default EmptyContent
