import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

const useCountdownTimer = (date) => {
  const [countdownDiff, setCountdownDiff] = useState(null)

  const calcCountdownDiff = (dt, interval) => {
    const diffDuration = dt.diffNow()
    setCountdownDiff(diffDuration)

    if (interval && diffDuration.as('seconds') < 1) {
      setCountdownDiff(null)
      clearInterval(interval)
    }
  }

  useEffect(() => {
    if (!date) return
    const dt = DateTime.fromISO(date).plus({ hours: 24 })

    const secondsToEnd = dt.diffNow().as('seconds')
    if (secondsToEnd < 1) return

    calcCountdownDiff(dt)

    const interval = setInterval(() => {
      calcCountdownDiff(dt, interval)
    }, 1000)

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval)
  }, [date])

  return [countdownDiff, setCountdownDiff]
}

export default useCountdownTimer
