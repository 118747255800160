import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'

import Layout from '~components/Layout'
import S from '~components/seo'
import Info from '~components/Info'
import ToggleMode from '~components/ToggleMode'
import useWindowSize from '~hooks/useWindowSize'
import getLibrary from '~utils/getLibrary'
import { AuthProvider } from '~hooks/useAuth'
import { TwitterAuthProvider } from '~hooks/useTwitterAuth'

import OverlayStateProvider from '~providers/OverlayStateProvider'

const IndexPage = () => {
  const [width] = useWindowSize()
  const isMobile = width < 640

  const isLogin = true

  return (
    <OverlayStateProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <AuthProvider>
          <TwitterAuthProvider>
            <Layout>
              <S />
              <Info />
              {((isMobile && !isLogin) || !isMobile) && <ToggleMode />}
            </Layout>
          </TwitterAuthProvider>
        </AuthProvider>
      </Web3ReactProvider>
    </OverlayStateProvider>
  )
}

export default IndexPage
